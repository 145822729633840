#about-us {
	height: 100%;

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		height: 671px;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}

		.sub-content {
			align-content: center;
			display: flex;
			height: 100%;
			position: relative;
			top: 0%;

			@include mq(tablet-down) {
				height: unset;
				top: 33.6%;
			}

			h1 {
				align-self: center;
				color: $color-white;
				font-family: $font-secondary;
				font-size: 55px;
				font-weight: $font-weight-light;
				line-height: 64px;
				text-align: center;
				margin: 0 auto 30px auto;
				width: 50%;
				z-index: 10;

				@include mq(tablet-down) {
					font-size: 23px;
					line-height: 34px;
					margin: 0 auto 29% auto;
					width: 90%;
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-content-block {

		.sub-content {
			@extend %container;
			display: flex;
			padding: 85px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
				padding: 35.5px 0 50px 0;
			}

			.sub-main-copy {
				display: flex;
				flex: 1;
				flex-direction: column;
				width: 50%;

				@include mq(tablet-down) {
					min-height: auto;
					width: 100%;
				}

				.sub-copy {
					max-width: 572px;

					@include mq(tablet-down) {
						max-width: unset;
						padding: 0;
						width: 100%;
					}

					h2 {
						color: $color-text-title;
						font-family: $font-secondary;
						font-size: 31px;
						font-weight: $font-weight-regular;
						letter-spacing: 0.6px;
						margin-bottom: 12px;

						@include mq(tablet-down) {
							font-size: 25px;
							margin-bottom: 10px;
							max-width: none;
						}
					}

					p {
						font-size: 14px;
						font-weight: $font-weight-regular;
						line-height: 29.3px;

						@include mq(tablet-down) {
							font-size: 13.3px;
							line-height: 26.9px;
							margin-bottom: 32px;
							max-width: none;
						}
					}

					.intro-text-wrapper {
						p {
							font-size: 16px;
							font-weight: $font-weight-regular;
							line-height: 30.5px;
							margin-bottom: 30px;

							@include mq(tablet-down) {
								font-size: 15px;
								line-height: 27px;
								margin-bottom: 31px;
								max-width: none;
							}
						}
					}

					.sub-divider {
						background-color: $color-primary;
						height: 3px;
						margin-bottom: 30px;
						width: 30px;

						@include mq(tablet-down) {
							height: 2px;
							margin-bottom: 29px;
						}
					}
				}
			}

			.sub-content-image {
				display: flex;
				justify-content: flex-end;
				min-height: 438px;
				width: 50%;

				@include mq(tablet-down) {
					height: 279px;
					min-height: unset;
					max-width: unset;
					width: 100%;
				}

				.content-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					max-width: 572px;
					min-height: 438px;
					width: 100%;

					@include mq(tablet-down) {
						height: 279px;
						min-height: unset;
						max-width: unset;
					}
				}
			}
		}

		&.mod-content-block-reverse {
			background-color: #e4e7ea;

			.sub-content {
				flex-direction: row-reverse;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.sub-main-copy {
					align-items: flex-end;
				}

				.sub-content-image {
					justify-content: flex-start;

					@include mq(tablet-down) {
						background-size: unset;
						height: 279px;
						min-height: unset;
						max-width: unset;
						order: 1;
						width: 100%;
					}

					.content-image {
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						max-width: 572px;
						min-height: 438px;
						width: 100%;

						@include mq(tablet-down) {
							height: 279px;
							min-height: unset;
							max-width: unset;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.section-meet-team {
		background-color: #e4e7ea;
		padding: 90px 0;
		width: 100%;

		@include mq(tablet-down) {
			margin: 0 auto;
			padding: 40px 0 80px 0;
		}

		.sub-content {
			@extend %container-small;

			.sub-title {

				h2 {
					color: $color-text-title;
					font-family: $font-secondary;
					font-size: 31px;
					font-weight: $font-weight-regular;
					margin-bottom: 55px;
					text-align: center;

					@include mq(tablet-down) {
						display: flex;
						flex-direction: column-reverse;
						font-size: 25px;
						margin-bottom: 12px;
						text-align: left;
					}

					span {
						color: #5B6464;
						font-size: 13px;
						font-weight: $font-weight-regular;
						letter-spacing: 1.6px;
						margin-left: 30px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 10px;
							margin: 0 0 8px 0;
						}
					}
				}

				.sub-divider {
					display: none;

					@include mq(tablet-down) {
						background-color: $color-primary;
						display: block;
						height: 2px;
						margin-bottom: 30px;
						width: 30px;
					}
				}
			}

			.sub-team {

				.list-team {
					display: flex;
					flex-wrap: wrap;
					list-style-type: none;
					margin: 0;
					position: relative;

					@include mq(tablet-down) {
						justify-content: space-between;
						overflow: hidden;
					}

					.item-team {
						margin: 0;
						overflow: hidden;
						padding: 0 29px;
						width: calc(100% / 4);

						@include mq(tablet-down) {
							align-items: center;
							display: flex;
							flex-direction: column;
							overflow: hidden;
							padding: 0;
							position: relative;
							width: calc((100% / 2) - 7px);
						}

						.team-info {
							background-color: #e4e7ea;
							padding: 20px 0;
							position: relative;

							@include mq(tablet-down) {
								margin: 7px 0 10px 0;
								padding: 0;
								position: relative;
								width: 100%;
							}

							h5 {
								color: $color-primary;
								font-family: $font-secondary;
								font-size: 20px;
								font-weight: $font-weight-regular;
								margin: 0;

								@include mq(tablet-down) {
									font-size: 16px;
									line-height: 20px;
								}
							}

							p {
								color: $color-black;
								font-family: $font-standard;
								font-size: 14px;
								font-weight: $font-weight-light;
								letter-spacing: 0.1px;

								@include mq(tablet-down) {
									font-size: 13px;
									letter-spacing: 0;
									line-height: 19px;
									margin-bottom: 0;
									margin-top: 8px;
								}
							}
						}

						.team-photo {
							background-position: center;
							background-repeat: no-repeat;
							background-size: cover;
							height: 226px;
							width: 230px;

							@include mq(tablet-down) {
								height: 164px;
								width: 166px;
							}
						}
					}
				}
			}
		}
	}
}