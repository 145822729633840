/* LINKS */

a {
	color: $color-link;
	text-decoration: none;

	&:active {
		background-color: transparent;
	}

	&:hover {
		color: $color-link-hover;
	}

	&:active,
	&:focus {
		color: $color-link-active;
		border: 0 none;
		outline: 0 none;
	}
}

/* GENERAL BUTTONS */

.btn {
	@extend %nofocus;

	@extend %noselect;

	border: none;
	outline: none;
	cursor: pointer;

	&.btn-primary {
		align-items: center;
		background: $color-buttons;
		border-radius: $radius-button;
		color: $color-white;
		display: inline-flex;
		font-size: $text-size-button;
		font-weight: $font-weight-semibold;
		letter-spacing: 0.5px;
		max-width: 210px;
		padding: $padding-button;
		transition: all 0.1s ease;
		width: 100%;
		display: flex;
		position: relative;
		overflow: hidden;
		z-index: 0;

		&:hover,
		&:focus {
			background: highlight-by($color-buttons, 5%);
		}

		&:disabled, &[disabled] {
			background: $color-text !important;
			box-shadow: 0 0 0 2px $color-text !important;
			color: $color-borders-light !important;
			border: 1px solid $color-text;
		}

		&:active {
			box-shadow: none;
			transition: none;
		}

		&.mod-ghost {
			background: transparent;
			color: $color-buttons;

			&:hover,
			&:active,
			&:focus {
				color: $color-white;
				background: highlight-by($color-buttons, 5%);
				box-shadow: 0 0 0 2px highlight-by($color-buttons, 5%);
			}
		}

		&.mod-link {
			padding: 0;
			background: transparent;
			color: $color-buttons;
			box-shadow: none;

			& + .btn {
				margin-left: 10px;
			}

			&:hover,
			&:active,
			&:focus {
				color: $color-buttons;
				background: transparent;
				box-shadow: none;
			}

			@include mq(mobile) {
				padding: 0;
				display: inline-block;
				text-align: unset;
			}
		}

		@include mq(mobile) {
			display: block;
			text-align: center;
			font-size: $font-size-med;
			padding-top: 18px;
			padding-bottom: 18px;
		}
	}

	&.btn-icon {
		padding: 12px;
		border: none;
		display: inline-block;
		font-size: $font-size-med;
		background: none;
	}
}
