#gallery {

	.grecaptcha-badge {
		display: none;
	}

	#app {
		position: relative;
	}

	.section-gallery {
		padding: 190px 0 120px 0;

		@include mq(tablet-down) {
			margin-bottom: 80px;
			padding: 90px 0 0 0;
		}

		.sub-content {
			@extend %container;

			.sub-page-title {

				> h1 {
					color: $color-text-title;
					font-family: $font-secondary;
					font-size: 32px;
					font-weight: $font-weight-regular;
					letter-spacing: 0.6px;
					margin-bottom: 10px;

					@include mq(tablet-down) {
						font-size: 25px;
						line-height: 37px;
						width: 85%;
					}
				}

				.sub-divider {
					background-color: $color-primary;
					height: 3px;
					margin-bottom: 30px;
					width: 30px;

					@include mq(tablet-down) {
						height: 2px;
					}
				}
			}

			.list-gallery-images {
				@extend %list-default;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;

				.item-gallery-image {
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					cursor: pointer;
					height: 218px;
					margin: 9px 0;
					width: 311px;

					@include mq(tablet-down) {
						height: 114px;
						margin: 4px 0;
						width: 159px;
					}

					.item-gallery-image-overlay {
						background-color: rgba(0,0,0,0.5);
						cursor: pointer;
						display: none;
						height: 100%;
						position: relative;
						width: 100%;

						svg {
							position: absolute;
							height: 100%;
							left: 42%;
							top: 40%;
							width: 100%;

							@include mq(tablet-down) {
								display: none;
							}
						}

						@include mq(tablet-down) {
							display: none;
						}
					}

					&:hover {

						.item-gallery-image-overlay {
							display: block;
						}
					}
				}
			}
		}
	}

	.section-lightbox {
		background-color: rgba(25,25,25,0.95);
		display: flex;
		left: 0;
		height: 100%;
		position: fixed;
		overflow: auto;
		top: 0;
		width: 100%;
		z-index: 99999;

		@include mq(tablet-down) {
			padding-top: 0;
		}

		.sub-lightbox-wrapper {
			align-items: center;
			display: flex;
			height: 651px;
			margin: auto;
			padding: 0;
			position: relative;
			max-width: 1200px;
			width: 90%;
			z-index: 99999;

			@include mq(tablet-down) {
				height: 100%;
				width: 98%;
			}

			.icon-cross {
				cursor: pointer;
				height: 25px;
				position: absolute;
				top: -20px;
				right: 45px;
				stroke: white;
				width: 25px;

				@include mq(tablet-down) {
					top: 20px;
					right: 12px;
				}
			}

			.slider-arrow-next, .slider-arrow-prev {
				cursor: pointer;
				height: 53px;
				stroke: #76777A;
				width: 28px;
				z-index: 99999;

				@include mq(tablet-down) {

				}
			}

			.slider-arrow-next {
				margin-left: 45.5px;

				@include mq(tablet-down) {
					margin-left: 15px;
				}
			}

			.slider-arrow-prev {
				margin-right: 45.5px;

				@include mq(tablet-down) {
					margin-right: 15px;
				}
			}

			.lightbox-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 100%;
				position: relative;
				width: 100%;
			}
		}
	}
}