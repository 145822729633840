#development {

	.section-hero-gallery {
		display: block;
		height: 671px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}

		.sub-content {
			align-items: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			position: absolute;
			text-align: center;
			width: 100%;
			z-index: 99;
		}

		.list-development-hero-images {
			margin: -2px 0 0 0;
			height: 100%;

			.item-hero-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				display: flex;
				height: 100%;
				margin: 0;
			}

			.slick-dots {
				align-items: center;
				display: flex;
				justify-content: center;
				margin: 30px 0;
				opacity: .6;
				position: relative;
				width: 100%;
				z-index: 999;

				@include mq(tablet-down) {
					display: none !important;
				}

				li {
					margin-left: 0;
					margin-right: 0;

					button {
						border-radius: 0;
						height: 3px;
						outline: none;
						width: 123px;
					}
				}

				.slick-active {
					background-color: $color-primary;
					height: 5px;
				}
			}

			.slick-list {
				height: 100%;
				padding: 0;

				.slick-track, .slick-slide, div {
					height: 100%;
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-development-body-block {
		display: block;
		position: relative;

		.sub-content {
			@extend %container;
			display: flex;
			padding: 85px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
				padding: 30px 0 40px 0;
			}

			.sub-main-copy {
				display: flex;
				flex: 1;
				flex-direction: column;
				width: 50%;

				@include mq(tablet-down) {
					min-height: auto;
					padding: 0;
					width: 100%;
				}

				.sub-copy {
					width: 90%;

					@include mq(tablet-down) {
						padding: 0;
						width: 100%;
					}

					h2 {
						color: $color-text-title;
						font-family: $font-secondary;
						font-size: 31px;
						font-weight: $font-weight-regular;
						letter-spacing: 0.6px;
						margin-bottom: 10px;

						@include mq(tablet-down) {
							font-size: 25px;
							max-width: none;
						}
					}

					p.development-location {
						color: #76777a;
						font-size: 15px;
						letter-spacing: 1px;
						margin-bottom: 3px;
						text-transform: uppercase;

						@include mq(tablet-down) {
							font-size: 14px;
							line-height: 15px;
							margin-bottom: 0;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 30.5px;
						margin-bottom: 30px;

						@include mq(tablet-down) {
							font-size: 15px;
							line-height: 27px;
							margin-bottom: 31px;
							max-width: none;
						}
					}

					a {
						color: $color-primary;
						font-weight: $font-weight-semibold;
						font-size: 16px;
					}

					.sub-divider {
						background-color: $color-primary;
						height: 3px;
						margin-bottom: 30px;
						width: 30px;

						@include mq(tablet-down) {
							height: 2px;
						}
					}

					> .sub-development-stats {
						display: flex;

						@include mq(tablet-down) {
							flex-direction: column;
						}

						.sub-stat-wrapper {
							display: flex;
							margin-right: 50px;

							@include mq(tablet-down) {
								align-items: center;
								margin-bottom: 30px;
							}

							svg {
								height: 27px;
								margin-right: 20px;
								width: 30px;

								@include mq(tablet-down) {
									height: 21px;
									width: 25px;
								}
							}

							> p {
								color: $color-primary;
								font-size: 13px;
								font-weight: $font-weight-semibold;
								letter-spacing: 0.6px;
								text-transform: uppercase;

								@include mq(tablet-down) {
									color: $color-primary;
									font-family: $font-standard;
									font-size: 12px;
									font-weight: $font-weight-semibold;
									letter-spacing: 1.2px;
									margin-bottom: 0;
									line-height: 14px;
									text-transform: uppercase;
								}
							}
						}
					}

					.sub-cta--mobile {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}

						h4 {
							font-family: $font-secondary;
							font-size: 17px;
							line-height: 28px;
							margin-bottom: 0;
						}
					}
				}
			}

			.sub-aside-block {
				background-color: #eeece6;
				display: flex;
				flex-direction: column;
				height: 100%;
				padding: 35px 40px;
				width: 40%;

				@include mq(tablet-down) {
					display: none;
					max-width: none;
					width: 100%;
				}

					h2 {
						color: $color-text-title;
						font-family: $font-secondary;
						font-size: 25px;
						font-weight: $font-weight-regular;
						letter-spacing: 0.4px;
						margin-bottom: 25px;

						@include mq(tablet-down) {
							font-size: 20px;
							max-width: none;
						}
					}

					p {
						font-size: 14.5px;
						font-weight: $font-weight-regular;
						max-height: 104px;
						line-height: 29px;
						width: 90%;

						@include mq(tablet-down) {
							font-size: 14px;
							max-width: none;
						}
					}

					h4 {
						color: $color-primary;
						font-size: 18px;
						font-weight: $font-weight-semibold;
						letter-spacing: 0.4px;
						line-height: 32px;
						margin-bottom: 1px;

						@include mq(tablet-down) {
							display: none;
						}
					}

					.download-button {
						justify-self: flex-end;
						align-items: center;
						border: 0;
						border-radius: 3px;
						background-color: $color-primary;
						color: $color-white;
						cursor: pointer;
						display: flex;
						font-size: 12px;
						font-weight: $font-weight-semibold;
						height: 48px;
						justify-content: center;
						letter-spacing: 0.6px;
						margin-top: 35px;
						text-align: center;
						text-transform: uppercase;
						width: 100%;

						&:hover {
							background-color: $color-white;
							color: $color-primary;
						}
					}
			}
		}

		.sub-aside-block--mobile {
			background-color: #eeece6;
			display: none;
			flex-direction: column;
			width: 50%;

			@include mq(tablet-down) {
				display: block;
				max-width: none;
				width: 100%;
			}

			.sub-content {
				@extend %container;

				h2 {
					color: $color-text-title;
					font-family: $font-secondary;
					font-size: 25px;
					font-weight: $font-weight-regular;
					letter-spacing: 0.4px;
					margin-bottom: 25px;

					@include mq(tablet-down) {
						font-size: 20px;
						max-width: none;
					}
				}

				p {
					font-size: 14px;
					font-weight: $font-weight-regular;
					max-height: 104px;
					line-height: 27px;
					width: 90%;

					@include mq(tablet-down) {
						font-size: 14px;
						max-width: none;
					}
				}

				h4 {
					color: $color-primary;
					font-size: 18px;
					font-weight: $font-weight-semibold;
					letter-spacing: 0.4px;
					line-height: 32px;
					margin-bottom: 1px;

					@include mq(tablet-down) {
						font-size: 15px;
						line-height: 29px;
						margin-bottom: 0;
					}
				}

				button {
					justify-self: flex-end;
					align-items: center;
					border: 0;
					border-radius: 3px;
					background-color: $color-primary;
					color: $color-white;
					cursor: pointer;
					display: flex;
					font-size: 13px;
					font-weight: $font-weight-semibold;
					height: 48px;
					justify-content: center;
					letter-spacing: 0.6px;
					margin-top: 35px;
					text-align: center;
					text-transform: uppercase;
					width: 100%;

					@include mq(tablet-down) {
						background-color: $color-white;
						color: $color-primary;
					}
				}
			}
		}
	}

	.section-interactive-map {
		background-color: #e7e8ec;
		display: flex;
		position: relative;
		margin-bottom: 70px;
		width: 100%;

		@include mq(tablet-down) {

		}

		.sub-content {
			@extend %container;
			padding: 70px 0;

			@include mq(tablet-down) {
				padding: 40px 0;
			}

			.site-plan-title-wrapper {
				position: relative;

				h2 {
					color: $color-text-title;
					font-family: $font-secondary;
					font-size: 31px;
					font-weight: $font-weight-regular;
					letter-spacing: 0.6px;
					margin-bottom: 10px;

					@include mq(tablet-down) {
						font-size: 21px;
						max-width: none;
					}
				}

				p.sub-heading {
					color: #76777a;
					font-size: 16px;
					letter-spacing: 1px;
					margin-bottom: 3px;
					text-transform: uppercase;

					@include mq(tablet-down) {
						font-size: 13px;
					}
				}

				.sub-divider {
					background-color: $color-primary;
					height: 3px;
					margin-bottom: 30px;
					width: 30px;

					@include mq(tablet-down) {
						height: 2px;
						margin-bottom: 10px;
					}
				}
			}

			.development-site-plan-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 600px;
				margin-left:auto;
				margin-right:auto;
				position: relative;
				width:960px;

				@include mq(tablet-down) {
					height: 400px;
					width: 340px;
				}

				.icon-dot {
					border: 2px solid white;
					border-radius: 50%;
					cursor: pointer;
					height: 22px;
					position: absolute;
					width: 22px;
					transition: all 2ms ease-in-out;

					@include mq(tablet-down) {
						height: 17px;
						width: 17px;
					}

					&--sold {
						background-color: #9e0000;
					}

					&--available {
						background-color: #5dba5c;
					}

					&--reserved {
						background-color: #dcb825;
					}

					&--not-released {
						background-color: #969696;
					}

					&:hover {
						height: 33px;
						width: 33px;
						transform: translateX(-19%) translateY(-19%);

						&:before {
							content: "+";
							color: #fff;
							font-size: 24px;
							left: 29%;
							position: absolute;
							top: -14%;
						}
					}

					&.mod-desktop {
						display: block;

						@include mq(tablet-down) {
							display: none;
						}
					}

					&.mod-tablet {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}
					}
				}
			}
		}

		.sub-plot-information {
			background-color: $color-primary;
			color: $color-white;
			padding: 50px;
			position: relative;
			margin-left: -300px;
			width: 600px;

			@include mq(tablet-down) {
				margin: 0;
				padding: 40px 22px;
				position: relative;
				width: 100%;
			}

			&::before {
				content: "";
				width: 0;
				height: 0;
				border-top: 25px solid transparent;
				border-bottom: 25px solid transparent;
				border-right: 25px solid $color-primary;
				position: absolute;
				left: -25px;
				top: 72px;
			}

			> p {
				font-size: 16px;
				letter-spacing: 1.6px;
				line-height: 19px;
				margin-bottom: 10px;
				text-transform: uppercase;

				@include mq(tablet-down) {
					font-size: 14px;
					line-height: 17px;
					margin: 0;
				}
			}

			> h1 {
				color: $color-white;
				font-family: $font-secondary;
				font-size: 32px;
				line-height: 42px;
				margin-bottom: 30px;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 32px;
					margin-bottom: 25px;
				}
			}

			> svg {
				cursor: pointer;
				height: 20px;
				position: absolute;
				right: 30px;
				stroke: white;
				top: 30px;
				width: 20px;
			}

			>img {
				margin-top: 30px;

				@include mq(tablet-down) {
					width: 100%;
				}
			}

			.sub-stat-wrapper {
				align-items: center;
				display: flex;
				margin-bottom: 20px;

				svg {
					height: 27px;
					margin-right: 38px;
					width: 27px;

					@include mq(tablet-down) {
						margin-right: 25px;
					}
				}

				p {
					font-size: 16px;
					letter-spacing: 1.6px;
					line-height: 19px;
					text-transform: uppercase;
					margin: 0;

					@include mq(tablet-down) {
						font-size: 12px;
					}
				}
			}

			.sub-text-wrapper {
				margin-top: 20px;

				p {
					font-size: 14px;
					line-height: 29px;
				}
			}
		}
	}

	.section-plots {
		display: block;
		padding: 0 0 70px 0;
		position: relative;

		@include mq(tablet-down) {
			padding: 50px 0;
		}

		.sub-content {
			@extend %container;

			h2 {
				color: $color-text-title;
				font-family: $font-secondary;
				font-size: 31px;
				font-weight: $font-weight-regular;
				letter-spacing: 0.6px;
				margin-bottom: 30px;

				@include mq(tablet-down) {
					font-size: 20px;
					margin-bottom: 20px;
					max-width: none;
				}
			}

			.plots-wrapper {
				display: block;

				@include mq(tablet-down) {
					display: none;
				}

				.list-plot-header, .list-plot-row {
					@extend %list-default;
					display: flex;
					font-size: 16px;
					padding: 20px 28px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: 13px;
						padding: 7px;
					}

					.item-plot {
						width: calc(100% / 7);

						@include mq(tablet-down) {
							width: calc(100% / 4);
						}

						button {
							border: none;
							background-color: #EEECE6;
							font-size: 16px;
							font-weight: $font-weight-semibold;

							&.mod-click {
								cursor: pointer;
							}
						}

						&.plot-name {
							width: 7%;

							@include mq(tablet-down) {
								width: 17%;
							}
						}

						&.plot-house-type {
							width: 25%;

							@include mq(tablet-down) {
								cursor: pointer;
								font-weight: $font-weight-bold;
								width: 30%;
							}
						}

						&.plot-property-type {
							width: 12%;
						}

						&.plot-number-beds {

							@include mq(tablet-down) {
								width: 18.5%;
							}
						}

						&.plot-sq-foot {
							width: 9%;
						}

						&.plot-price {
							width: 21%;

							@include mq(mobile) {
								width: 33%;
							}
						}

						&.plot-floorplan {
							width: 11%;

							@include mq(tablet-down) {
								width: auto;
							}

							button {
								text-align: left;
							}
						}
					}
				}

				.list-plot-header {
					background-color: $color-primary;
					color: $color-white;
					font-size: 13.5px;
					letter-spacing: 1px;
					text-transform: uppercase;
				}

				.list-plot-row {
					background-color: #eeece6;
					border-bottom: 1px solid #d8d8d8;

					&:last-child {
						border-bottom: none;
					}
				}

				&--mobile {
					@extend .plots-wrapper;
					display: none;

					@include mq(tablet-down) {
						display: block;
					}
				}
			}
		}
	}

	.section-gallery {
		background-color: #EEECE6;
		display: block;
		margin: 50px 0 0 0;
		padding: 70px 0;
		position: relative;

		@include mq(tablet-down) {
			background-color: $color-white;
			margin: 50px 0 30px 0;
			padding: 0;
		}

		.sub-content {
			@extend %container;

			h2 {
				color: $color-text-title;
				font-family: $font-secondary;
				font-size: 31px;
				font-weight: $font-weight-regular;
				letter-spacing: 0.6px;
				margin-bottom: 30px;

				@include mq(tablet-down) {
					font-size: 20px;
					margin-bottom: 20px;
					max-width: none;
				}
			}
		}

		.list-gallery-images {
			@extend %list-default;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;

			@include mq(tablet-down) {
				display: none;
			}

			&--mobile {
				@extend .list-gallery-images;
				display: none;

				@include mq(tablet-down) {
					display: block;
				}

				.slick-slide {
					display: block;
					opacity: .5;
					margin: 0 5px;
				}

				.slick-active {
					opacity: 1;
				}

				.item-gallery-image {
					width: 100%;
				}
			}

			.item-gallery-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: 9px 0;
				height: 211px;
				width: calc((100% /4) - 15px);

				.item-gallery-image-overlay {
					background-color: rgba(0,0,0,0.5);
					cursor: pointer;
					display: none;
					height: 100%;
					position: relative;
					width: 100%;

					@include mq(tablet-down) {
						display: none;
					}

					svg {
						position: absolute;
						height: 100%;
						left: 42%;
						top: 40%;
						width: 100%;

						@include mq(tablet-down) {
							display: none;
						}
					}
				}

				&:hover {

					.item-gallery-image-overlay {
						display: block;

						@include mq(tablet-down) {
							display: none;
						}
					}
				}
			}
		}
	}

	.section-local-area {
		background-color: $color-white;
		display: block;
		position: relative;

		@include mq(tablet-down) {
			display: none;
		}

		h2 {
			color: $color-text-title;
			font-family: $font-secondary;
			font-size: 31px;
			font-weight: $font-weight-regular;
			letter-spacing: 0.6px;
			margin-bottom: 50px;

			@include mq(tablet-down) {
				font-size: 22px;
				max-width: none;
			}
		}

		.sub-tab-wrapper {
			background-color: #EEECE6;

			.sub-content {
			@extend %container;

				.list-tab-boxes {
					@extend %list-default;
					display: flex;

					.item-tab-box {
						cursor: pointer;
						font-size: 16px;
						font-weight: $font-weight-semibold;
						letter-spacing: 1.07px;
						padding: 19px 15px;
						text-align: center;
						text-transform: uppercase;
						width: calc(100% / 4);

						&.tab-active {
							background-color: white;
							color: #76777a;
						}
					}
				}
			}
		}

		.sub-information-wrapper {

			.sub-content {
				@extend %container;

				.list-information-boxes {
					@extend %list-default;

					.item-information-box {
						display: flex;
						padding: 60px 0;

						&.tab-active {
							display: flex;
						}

						.sub-content-wrapper {
							width: 50%;

							p {
								font-size: 14px;
								line-height: 29px;
							}
						}

						.sub-image-wrapper {
							display: flex;
							justify-content: flex-end;
							width: 50%;

							.content-image {
								background-position: center center;
								background-size: cover;
								background-repeat: no-repeat;
								height: 438px;
								width: 558px;
							}
						}
					}
				}
			}
		}
	}

	.section-local-area--mobile {
		background-color: $color-white;
		display: none;
		padding-bottom: 20px;
		position: relative;

		@include mq(tablet-down) {
			display: block;
		}

		h2 {
			font-size: 20px;
			margin-bottom: 20px;
			max-width: none;
		}

		.sub-tab-wrapper {

			.sub-content {
			@extend %container;

				.list-tab-boxes {
					@extend %list-default;
					display: flex;
					flex-direction: column;

					.item-tab-box {
						align-items: center;
						background-color: #EEECE6;
						color: $color-text-title;
						display: flex;
						justify-content: space-between;
						font-size: 13px;
						font-weight: $font-weight-semibold;
						letter-spacing: 1.07px;
						margin-bottom: 10px;
						padding: 12px 10px;
						text-transform: uppercase;
						width: 100%;

						svg {
							color: #76777A;
							height: 10px;
							transition: all 270ms ease-out;
							width: 14px;
						}

						&.tab-active {
							color: #76777a;

							svg {
								transition: all 270ms ease-out;
								transform: rotate(-180deg);
							}
						}
					}

					.item-information-box {
						display: flex;
						flex-direction: column;
						padding: 20px 0;

						&.tab-active {
							display: flex;
						}

						.sub-content-wrapper {
							width: 100%;

							p {
								font-size: 14px;
								line-height: 27px;
							}
						}

						.content-image {
							background-position: center center;
							background-size: contain;
							background-repeat: no-repeat;
							margin: 15px 0;
							min-height: 259px;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.section-key-features {
		background-color: $color-primary;
		display: block;
		padding: 108px 0 70px 0;
		position: relative;

		@include mq(tablet-down) {
			padding: 30px 0;
		}

		.sub-content {

			@include mq(tablet-down) {
				width: 100%;
				max-width: calc(100% - 44px);
				margin: 0px auto;
			}

			h2 {
				color: $color-white;
				font-family: $font-secondary;
				font-size: 31px;
				font-weight: $font-weight-regular;
				letter-spacing: 0.6px;
				margin-bottom: 50px;
				text-align: center;

				@include mq(tablet-down) {
					font-size: 20px;
					font-weight: $font-weight-thin;
					margin-bottom: 20px;
					max-width: none;
					text-align: left;
				}
			}
		}

		.list-key-features {
			@extend %list-default;
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			width: 60%;

			@include mq(tablet-down) {
				margin-top: 40px;
				width: 90%;
			}

			.item-key-feature {
				color: $color-white;
				font-size: 16px;
				height: 125px;
				text-align: center;
				width: calc(100% / 4);

				@include mq(tablet-down) {
					width: calc(100% / 3);
				}

				.sub-image-wrapper {

					.content-image {

						@include mq(tablet-down) {
							height: 38px;
						}
					}
				}

				.sub-content-wrapper {
					font-size: 16px;

					@include mq(tablet-down) {
						font-size: 13px;
					}
				}
			}
		}
	}

	.section-location {
		display: block;
		padding: 50px 0;
		position: relative;

		@include mq(tablet-down) {
			padding: 30px 0;
		}

		h2 {
			color: $color-text-title;
			font-family: $font-secondary;
			font-size: 31px;
			font-weight: $font-weight-regular;
			letter-spacing: 0.6px;
			margin-bottom: 40px;
			text-align: center;

			@include mq(tablet-down) {
				font-size: 20px;
				margin: 0 0 20px 20px;
				max-width: none;
				text-align: left;
			}
		}

		#developmentmap {
			height: 500px;
			margin: 0px auto;
			max-width: calc(100% - 44px);
			width: 100%;

			@include mq(tablet-down) {
				height: 430px;
				margin: 0;
				max-width: unset;
				width: 100%;
			}
		}
	}

	.section-lightbox {
		background-color: rgba(25,25,25,0.95);
		display: flex;
		left: 0;
		height: 100%;
		position: fixed;
		overflow: auto;
		top: 0;
		width: 100%;
		z-index: 1;

		@include mq(tablet-down) {
			padding-top: 0;
		}

		.sub-lightbox-wrapper {
			align-items: center;
			display: flex;
			height: 651px;
			margin: auto;
			padding: 0;
			position: relative;
			max-width: 1200px;
			width: 90%;

			@include mq(tablet-down) {
				height: 100%;
				width: 98%;
			}

			.icon-cross {
				cursor: pointer;
				height: 25px;
				position: absolute;
				top: -20px;
				right: 45px;
				stroke: white;
				width: 25px;

				@include mq(tablet-down) {
					right: 10px;
					top: 20px;
				}
			}

			.slider-arrow-next, .slider-arrow-prev {
				cursor: pointer;
				height: 53px;
				stroke: #76777A;
				width: 28px;
				z-index: 99999;

				@include mq(tablet-down) {

				}
			}

			.slider-arrow-next {
				margin-left: 45.5px;

				@include mq(tablet-down) {
					margin-left: 15px;
				}
			}

			.slider-arrow-prev {
				margin-right: 45.5px;

				@include mq(tablet-down) {
					margin-right: 15px;
				}
			}

			.lightbox-image {
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				height: 100%;
				position: relative;
				width: 100%;
			}
		}
	}
}
