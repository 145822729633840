#careers {

	.section-careers {
		padding: 190px 0 0 0;

		@include mq(tablet-down) {
			margin: 0;
			padding: 90px 0 0 0;
		}

		.sub-content {
			@extend %container;
			display: flex;
			padding: 0 0 85px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
				padding: 0;
			}

			.sub-main-copy {
				display: flex;
				flex: 1;
				flex-direction: column;
				width: 50%;

				@include mq(tablet-down) {
					min-height: auto;
					width: 100%;
				}

				.sub-copy {
					max-width: 572px;

					@include mq(tablet-down) {
						max-width: unset;
						padding: 0;
						width: 100%;
					}

					h2 {
						color: $color-text-title;
						font-family: $font-secondary;
						font-size: 31px;
						font-weight: $font-weight-regular;
						letter-spacing: 0.6px;
						margin-bottom: 12px;

						@include mq(tablet-down) {
							font-size: 25px;
							margin-bottom: 10px;
							max-width: none;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 30.5px;
						margin-bottom: 30px;

						@include mq(tablet-down) {
							font-size: 15px;
							line-height: 27px;
							margin-bottom: 31px;
							max-width: none;
						}
					}

					.sub-divider {
						background-color: $color-primary;
						height: 3px;
						margin-bottom: 30px;
						width: 30px;

						@include mq(tablet-down) {
							height: 2px;
							margin-bottom: 29px;
						}
					}
				}
			}

			.sub-content-image {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				@include mq(tablet-down) {
					height: 279px;
					min-height: unset;
					max-width: unset;
					width: 100%;
				}

				.content-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					height: 438px;
					margin-top: 93px;
					max-width: 557px;
					width: 100%;

					@include mq(tablet-down) {
						height: 279px;
						min-height: unset;
						max-width: unset;
						margin-top: 0;
					}
				}
			}
		}
	}

	.section-current-vacancies {

		@include mq(tablet-down) {
			margin-top: 40px;
		}

		.sub-content {
			@extend %container;

			h2 {
				color: $color-text-title;
				font-family: $font-secondary;
				font-size: 31px;
				font-weight: $font-weight-regular;
				letter-spacing: 0.6px;
				margin-bottom: 12px;

				@include mq(tablet-down) {
					font-size: 25px;
					max-width: none;
				}
			}

			.sub-divider {
				background-color: $color-primary;
				height: 3px;
				margin-bottom: 30px;
				width: 30px;

				@include mq(tablet-down) {
					height: 2px;
				}
			}

			.list-current-vacancies {
				@extend %list-default;

				.item-current-vacancy {
					background-color: #eeece6;
					display: flex;
					padding: 20px 25px;
					margin-bottom: 20px;

					@include mq(tablet-down) {
						flex-wrap: wrap;
						padding: 20px 15px;
					}

					.content-wrapper-left {
						width: 75%;

						@include mq(tablet-down) {
							width: 100%;
						}

						h2 {
							font-size: 25px;
							line-height: 32px;

							@include mq(tablet-down) {
								font-size: 20px;
							}
						}

						p {
							margin: 18px 0 0 0;
							width: 95%;
						}

						.role-type, .divider {
							color: #76777a;
							font-size: 15px;
							letter-spacing: 0.8px;
							margin-right: 10px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								font-size: 12px;
							}
						}

						.salary {
							color: #76777a;
							font-size: 15px;
							letter-spacing: 0.8px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								font-size: 12px;
							}
						}
					}

					.content-wrapper-right {
						align-self: flex-end;
						display: flex;
						height: 100%;
						justify-content: flex-end;
						width: 30%;

						@include mq(tablet-down) {
							width: 100%;
						}

						.download-button {
							background-color: $color-white;
							border-radius: 3px;
							color: $color-primary;
							font-family: $font-standard;
							font-size: 15px;
							font-weight: $font-weight-semibold;
							letter-spacing: 0.6px;
							text-align: center;
							text-transform: uppercase;
							padding: 10px 0;
							width: 290px;

							@include mq(tablet-down) {
								font-size: 13px;
								margin-top: 30px;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.section-footer-image {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 389px;
		margin-top: 120px;
		width: 100%;

		@include mq(tablet-down) {
			height: 375px;
			margin-top: 55px;
		}
	}
}