#home {

	.section-hero-gallery {
		display: block;
		height: 805px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 474px;
		}

		.sub-content {
			align-items: center;
			display: flex;
			flex-direction: column;
			position: absolute;
			text-align: center;
			top: 35.5%;
			width: 100%;
			z-index: 99;

			@include mq(tablet-down) {
				height: unset;
				top: 31.4%;
			}

			h1 {
				color: $color-white;
				font-family: $font-secondary;
				font-size: 55px;
				font-weight: $font-weight-light;
				letter-spacing: 1.6px;
				line-height: 64px;
				text-align: center;
				margin: 0 auto 30px auto;
				width: 50%;

				@include mq(tablet-down) {
					font-size: 30px;
					line-height: 37px;
					margin: 0 auto 29% auto;
					width: 90%;
				}
			}

			p {
				color: $color-white;
				font-size: 16px;
				letter-spacing: 1.6px;
				text-transform: uppercase;

				@include mq(tablet-down) {
					font-size: 14.8px;
					letter-spacing: 1.2px;
					margin-bottom: 10px;
				}
			}

			.sub-divider {
				background-color: $color-white;
				height: 3px;
				width: 34px;

				@include mq(tablet-down) {
					height: 2px;
					width: 25px;
				}
			}
		}


		.list-home-hero-images {
			margin: -2px 0 0 0;
			height: 100%;

			.item-home-hero-image {
				background-position: center;
				background-size: cover;
				display: flex;
				height: 806px;
				margin: 0;

				@include mq(tablet-down) {
					height: 474px;
				}
			}

			.slick-list {
				padding: 0;
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;

			@include mq(tablet-down) {
				z-index: 1;
			}
		}

	}

	.section-development-blocks {
		position: relative;
		z-index: 1;

		@include mq(mobile) {
			margin-top: -34px;
		}

		.sub-content {

			@include mq(mobile) {
				padding: 0 10px;
			}

			.list-development-blocks {
				@extend %list-default;
				display: flex;
				margin: 0;
				padding: 0;
				width: 100%;

				@include mq(mobile) {
					flex-direction: column;
				}

				.item-development-block {
					display: flex;
					flex-direction: column;
					position: relative;
					width: calc(100% / 4);

					@include mq(mobile) {
						margin-bottom: 12px;
						width: 100%;
					}

					&:hover {

						.development-block-info-wrapper {
							background-color: $color-primary;
							// cursor: pointer;

							h2 {
								color: $color-white;
							}

							svg {
								display: block;
								position: absolute;
								height: 9px;
								stroke: $color-white;
								top: 22px;
								right: 44px;
								width: 6px;

								@include mq(mobile) {
									height: 11px;
									right: 15px;
									top: 25px;
								}
							}
						}


					}

					a {
						height: 100%;
						left: 0;
						cursor: pointer;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 1;
					}

					.development-image {
						align-self: flex-start;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						height: 283px;
						width: 100%;

						@include mq(mobile) {
							height: 50vw;
							min-height: 256px;
						}
					}

					.development-block-info-wrapper {
						align-self: flex-end;
						background-color: #eeece6;
						display: flex;
						min-height: 81px;
						position: relative;
						width: 100%;

						h2 {
							color: $color-primary;
							font-family: $font-secondary;
							font-size: 18px;
							font-weight: 100;
							letter-spacing: 1px;
							line-height: 24px;
							margin: 0;
							padding-left: 40px;
							padding-top: 14px;
							width: 85%;

							@include mq(mobile) {
								padding-top: 15px;
								padding-left: 11px;
							}
						}

						svg {
							display: block;
							position: absolute;
							height: 9px;
							stroke: $color-primary;
							top: 22px;
							right: 44px;
							width: 6px;

							@include mq(mobile) {
								display: block;
								position: absolute;
								height: 11px;
								top: 25px;
								right: 15px;
								width: 6px;
							}
						}
					}
				}
			}
		}
	}

	.section-home-body-block {

		.sub-content {
			@extend %container;
			display: flex;
			padding: 121px 0 85px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
				padding: 22px 0 32px 0;
			}

			.sub-main-copy {
				display: flex;
				flex: 1;
				flex-direction: column;
				width: 50%;

				@include mq(tablet-down) {
					min-height: auto;
					width: 100%;
				}

				.sub-copy {
					width: 90%;

					@include mq(tablet-down) {
						padding: 0;
					}

					h2 {
						color: $color-text-title;
						font-family: $font-secondary;
						font-size: 31px;
						font-weight: $font-weight-regular;
						letter-spacing: 0.6px;
						margin-bottom: 14px;

						@include mq(tablet-down) {
							font-size: 25px;
							margin-bottom: 18px;
							max-width: none;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 30.5px;
						margin-bottom: 30px;

						@include mq(tablet-down) {
							font-size: 15px;
							line-height: 25.2px;
							max-width: none;
						}
					}

					a {
						color: $color-primary;
						font-weight: $font-weight-semibold;
						font-size: 15px;

						&:hover {
							color: $color-link-hover;
						}

						@include mq(tablet-down) {
							display: flex;
							font-family: $font-secondary;
							font-weight: $font-weight-light;

						}

						svg {
							display: none;

							@include mq(tablet-down) {
								display: block;
								height: 9px;
								left: 14px;
								position: relative;
								stroke: $color-primary;
								top: 9px;
								width: 10px;
							}
						}
					}

					.sub-divider {
						background-color: $color-primary;
						height: 3px;
						margin-bottom: 35px;
						width: 30px;

						@include mq(tablet-down) {
							height: 2px;
						}
					}
				}
			}

			.sub-content-image {
				display: flex;
				justify-content: flex-end;
				width: 50%;

				@include mq(tablet-down) {
					width: 100%;
				}

				.content-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					height: 438px;
					width: 90%;

					@include mq(tablet-down) {
						height: 283px;
						margin-top: 32px;
						width: 100%;
					}
				}
			}
		}

	}

	.section-testimonials {
		@extend %container;
		height: auto;
		margin: 59px auto 123px auto;
		width: 100%;

		@include mq(tablet-down) {
			background-color: $color-secondary;
			height: unset;
			margin: 0;
			max-width: unset;
			width: 100%;
		}

		.sub-content {
			display: flex;
			height: 100%;
			width: 100%;

			@include mq(tablet-down) {
				flex-direction: column-reverse;
				max-width: unset;

			}

			.sub-testimonials {
				background-color: rgba(10,34,64,0.1);
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: auto;
				max-height: 410px;
				padding: 0 100px 0 60px;
				width: calc((100% / 3) * 2);

				@include mq(tablet-down) {
					height: 100%;
					padding: 54px 21px 55px 21px;
					max-height: unset;
					width: 100%;

					@include mq(tablet-down) {
						padding: 54px 21px 30px 21px;
					}
				}

				h2 {
					color: $color-primary;
					font-family: $font-secondary;
					font-size: 25px;
					margin-bottom: 29px;

					@include mq(tablet-down) {
						margin-bottom: 28px;
					}
				}

				.list-testimonials {
					@extend %list-default;

					@include mq(tablet-down) {
						margin: 0;
					}

					li.item-testimonial {
						margin: 0;

						@include mq(tablet-down) {
							margin: 0;
						}
					}

					p {
						color: $color-primary;
						font-size: 16px;
						font-style: italic;
						letter-spacing: 0.25px;
						line-height: 31px;
						margin: 0 0 11px 0;
						width: 95%;

						@include mq(tablet-down) {
							font-size: 15px;
							margin: 0 0 26px 0;
						}
					}

					.slick-dots {
						align-items: center;
						background-color: #CBC7BB;
						bottom: -24px;
						display: flex !important;
						left: 0;
						opacity: .6;
						position: absolute;
						width: 0;

						@include mq(tablet-down) {
							display: none !important;
						}

						li {
							margin-left: 0;
							margin-right: 0;

							button {
								border-radius: 0;
								height: 3px;
								outline: none;
								width: 80px;
							}
						}

						.slick-active {
							background-color: $color-primary;
							height: 5px;
						}
					}

					.testimonial-name {
						color: $color-text-title;
						font-size: 12px;
						font-style: unset;
						font-weight: $font-weight-semibold;
						margin-bottom: 0;
						letter-spacing: 0.4px;
						text-transform: capitalize;

						@include mq(tablet-down) {
							margin: 0 0 25px 0;
						}
					}
				}
			}

			.list-testimonial-images {
				margin: 0;
				width: calc((100% / 3) * 1);

				@include mq(mobile) {
					width: 100%;
				}

				.item-testimonial-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					min-height: 410px;
					list-style-type: none;
					margin: 0;
					width: 100%;

					@include mq(tablet-down) {
						max-width: none;
					}
				}
			}
		}
	}
}
