#why-buy-new {

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 671px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}

		.sub-content {
			align-content: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;

			h1 {
				align-self: center;
				color: $color-white;
				font-family: $font-secondary;
				font-size: 48px;
				font-weight: $font-weight-light;
				text-align: center;
				margin: 0 auto;
				width: 50%;
				z-index: 10;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 37px;
					width: 85%;
				}
			}

			a {
				align-self: center;
				background-color: #eeece6;
				display: flex;
				font-family: $font-standard;
				font-size: 15px;
				font-weight: $font-weight-light;
				justify-content: center;
				letter-spacing: 1px;
				margin-top: 50px;
				padding: 20px 30px;
				text-transform: uppercase;
				z-index: 10;

				@include mq(tablet-down) {
					display: none;
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-content-block {

		.sub-content {
			@extend %container;
			display: flex;
			padding: 85px 0;

			@include mq(tablet-down) {
				flex-direction: column;
				justify-content: unset;
				padding: 40px 0 70px 0;
			}

			.sub-main-copy {
				display: flex;
				flex: 1;
				flex-direction: column;
				width: 50%;

				@include mq(tablet-down) {
					min-height: auto;
					width: 100%;
				}

				.sub-copy {
					max-width: 572px;

					@include mq(tablet-down) {
						max-width: unset;
						padding: 0;
						width: 100%;
					}

					h2 {
						color: $color-text-title;
						font-family: $font-secondary;
						font-size: 31px;
						font-weight: $font-weight-regular;
						letter-spacing: 0.6px;
						margin-bottom: 12px;

						@include mq(tablet-down) {
							font-size: 25px;
							max-width: none;
						}
					}

					p {
						font-size: 16px;
						font-weight: $font-weight-regular;
						line-height: 30.5px;
						margin-bottom: 30px;

						@include mq(tablet-down) {
							font-size: 15px;
							line-height: 27px;
							margin-bottom: 31px;
							max-width: none;
						}

						&:last-child {
							margin-bottom: 40px;
						}
					}

					.sub-divider {
						background-color: $color-primary;
						height: 3px;
						margin-bottom: 30px;
						width: 30px;

						@include mq(tablet-down) {
							height: 2px;
						}
					}
				}

				a {
					display: none;

					@include mq(tablet-down) {
						background-color: #eeece6;
						display: flex;
						font-family: $font-standard;
						font-weight: $font-weight-light;
						justify-content: center;
						text-transform: uppercase;
						z-index: 10;
						margin-bottom: 35px;
						font-size: 13px;
						margin-top: 0;
						padding: 15px 17px;
						align-self: flex-start;
						letter-spacing: 0.6px;
					}
				}
			}

			.sub-content-image {
				display: flex;
				justify-content: flex-end;
				min-height: 438px;
				width: 50%;

				@include mq(tablet-down) {
					height: 279px;
					min-height: unset;
					max-width: unset;
					width: 100%;
				}

				.content-image {
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					max-width: 572px;
					min-height: 438px;
					width: 100%;

					@include mq(tablet-down) {
						height: 279px;
						min-height: unset;
						max-width: unset;
					}
				}
			}
		}

		&.mod-content-block-reverse {
			background-color: #e4e7ea;

			.sub-content {
				flex-direction: row-reverse;

				@include mq(tablet-down) {
					flex-direction: column;
				}

				.sub-main-copy {
					align-items: flex-end;
				}

				.sub-content-image {
					justify-content: flex-start;

					@include mq(tablet-down) {
						background-size: unset;
						height: 279px;
						min-height: unset;
						max-width: unset;
						order: 1;
						width: 100%;
					}

					.content-image {
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						max-width: 572px;
						min-height: 438px;
						width: 100%;

						@include mq(tablet-down) {
							height: 279px;
							min-height: unset;
							max-width: unset;
							width: 100%;
						}
					}
				}
			}
		}
	}
}