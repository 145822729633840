#current-developments, #past-developments {

	.section-current-developments, .section-past-developments {
		padding: 190px 0 0 0;

		@include mq(tablet-down) {
			margin: 0;
			padding: 90px 0 0 0;
		}

		.sub-content {
			@extend %container;

			> h1 {
				color: $color-primary;
				font-family: $font-secondary;
				font-size: 32px;
				font-weight: $font-weight-light;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 37px;
					width: 85%;
				}
			}

			.sub-divider {
				background-color: $color-primary;
				height: 3px;
				margin: 20px 0;
				width: 34px;

				@include mq(tablet-down) {
					height: 2px;
				}
			}

			.sub-development-blocks {
				margin: 50px 0 120px 0;
				width: 100%;

				@include mq(tablet-down) {
					margin: 40px 0;
				}

				.list-development-blocks {
					@extend %list-default;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					width: 100%;

					@include mq(tablet-down) {
						flex-direction: column;
					}

					.item-development-block {
						@extend %list-default;
						display: flex;
						flex-direction: column;
						width: calc((100% / 3) - 10px);

						@include mq(tablet-down) {
							margin-bottom: 17px;
							width: 100%;
						}

						&:nth-child(n+4) {
							margin-top: 20px;

							@include mq(tablet-down) {
								margin-top: 0;
							}
						}

						.development-block-image {
							align-self: flex-start;
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
							height: 227px;
							width: 100%;
						}

						.development-details-wrapper {
							background-color: #eeece6;
							height: 346px;
							padding: 25px;

							@include mq(tablet-down) {
								height: unset;
							}

							.development-location {
								color: #76777a;
								font-size: 13px;
								letter-spacing: 1.15px;
								margin-bottom: 5px;
								text-transform: uppercase;

								@include mq(tablet-down) {
									font-size: 14px;
								}
							}

							.development-name {
								color: $color-primary;
								font-family: $font-secondary;
								font-size: 22px;
								font-weight: 100;
								letter-spacing: 0.8px;
								line-height: 32px;
								margin: 0;

								@include mq(tablet-down) {
									font-size: 20px;
								}
							}

							.development-beds-info {
								align-items: center;
								display: flex;
								height: 60px;

								@include mq(tablet-down) {
									height: 52px;
								}

								> p {
									color: $color-primary;
									font-size: 12px;
									font-weight: $font-weight-semibold;
									letter-spacing: 1.2px;
									margin: 0;
									text-transform: uppercase;
								}
							}

							.development-learn-more-button {
								justify-self: flex-end;
								align-items: center;
								background-color: $color-white;
								border-radius: 5px;
								color: $color-primary;
								display: flex;
								font-size: 13.5px;
								font-weight: $font-weight-semibold;
								height: 48px;
								justify-content: center;
								letter-spacing: 0.6px;
								text-align: center;
								text-transform: uppercase;
								width: 100%;

								&.mod-hidden {
									visibility: hidden;
								}
							}

							p:nth-child(n+2) {
								font-size: 14px;
								line-height: 27px;
								margin-bottom: 20px;
								height: 120px;
							}
						}
					}
				}
			}
		}
	}
}