.section-cookies {
	align-items: center;
	background: $color-primary;
	display: flex;
	padding: 20px 0;
	position: relative;
	width: 100%;
	z-index: 999;

	@media screen and (max-width: 1272px){
		height: auto;
		padding: 10px 0 20px;
	}

	&.mod-hide {
		display: none;
	}

	.sub-content {
		@extend %container-large;

		display: flex;
		flex-direction: column;


		.sub-text {
			flex: 1;

			& > p {
				color: rgba($color-white, 0.7);
				font-size: $font-size-small;
				line-height: $line-height-base;
				margin-bottom: 0;

				a {
					color: rgba($color-white, 0.7);
					font-weight: $font-weight-semibold;
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 0;
			margin-top: 20px;


			.btn {
				background: none;
				border: 2px solid rgba($color-white, 0.25);
				color: $color-white;
				display: inline-block;
				font-family: $headings-font !important;
				transition: background .3s ease;

				font-size: $font-size-small;
				padding: 4px 0;
				width: 115px;


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.header-main {
	border: 1px solid $color-neutral;
	height: 103px;
	left: 0;
	position: absolute;
	width: 100%;

	@include mq(tablet-down) {
		// border-bottom: 1px solid #eeece6;
		height: 65px;
	}

	.sub-container {
		display: flex;
		flex-wrap: unset;
		height: 100%;
		padding: 0 17px;
		position: relative;
		width: 100%;

		.sub-logo {
			align-items: flex-end;
			position: absolute;
			height: 47px;
			left: 45px;
			top: 28px;
			width: 316px;
			z-index: 999;

			@include mq(tablet-down) {
				height: 28.23px;
				left: 18px;
				top: 20px;
				width: 190px;
			}

			a {
				display: block;
				z-index: 999;

				@include mq(tablet-down) {
					width: 190px;
					height: 28.23px;
				}

				svg {
					color: $color-primary;
					height: 50px;
					z-index: 999;

					@include mq(tablet-down) {
						width: 190px;
						height: 28.23px;
					}
				}
			}
		}

		.mob-hamburger {
			align-items: flex-end;
			cursor: pointer;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			right: 45px;
			top: 50px;
			transform: translateY(-50%);
			transition: width .3s ease;
			width: 200px;
			z-index: 999;

			@include mq(tablet-down) {
				right: 25px;
				top: 34px;
			}

			span {
				color: $color-primary;
				font-size: 13px;
				margin-right: 30px;

				@include mq(tablet-down) {
					display: none;
				}
			}

			.menu-icon-wrapper {

				.mob-bar {
					background: $color-primary;
					border-radius: 20px;
					display: block;
					height: 2px;
					margin-bottom: 8px;
					opacity: 1;
					transform: rotate(0deg);
					transition: .4s ease-in-out;
					width: 35px;
					z-index: 99999;

					@include mq(tablet-down) {
						margin-bottom: 6px;
						width: 23px;
					}
				}

				.mob-bar-1 {
					top: 0;
				}

				.mob-bar-2 {
					top: 8px;
				}

				.mob-bar-3 {
					margin-bottom: 0;
					top: 16px;
					width: 14px;
				}
			}

			&.mod-active {
				position: fixed;
				z-index: 999999;

				.mob-bar {
					background: $color-primary;
				}

				.mob-bar-1 {
					top: 10px;
					position: absolute;
					right: 0;
					transform: rotate(135deg);
					width: 30px;

				}

				.mob-bar-2 {
					display: none;
					left: -60px;
					opacity: 0;
				}

				.mob-bar-3 {
					transform: rotate(-135deg);
					top: 10px;
					position: absolute;
					right: 0;
					width: 30px
				}

				& + #nav {
					visibility: visible;
				}

				span {
					display: none;
				}
			}
		}

		.mod-white {

			span {
				color: $color-white;
			}

			.menu-icon-wrapper {
				height: 23px;
				z-index: 2;

				.mob-bar {
					background: $color-white;
				}
			}

			&.mod-active {
				z-index: 999999;

				.mob-bar {
					background: $color-primary;
				}
			}
		}

	}

	nav {
		align-items: center;
		background: $color-white;
		display: none;
		filter: drop-shadow(0px 0px 9px rgba(0,0,0,.1));
		height: 100%;
		justify-self: flex-end;
		margin-left: auto;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		width: 340px;

		@include mq(tablet-down) {
			right: unset;
			width: 100vw;
		}

		&.mod-active {
			display: flex;
			position: fixed;
			z-index: 999;
		}

		.list-nav {
			@extend %list-default;
			background-color: $color-white;
			border-left: 1px solid $color-neutral;
			flex-direction: column;
			height: 100%;
			overflow-y: scroll;
			padding: 90px 55px 0 45px;
			position: relative;
			width: 340px;

			@include mq(tablet-down) {
				border: none;
				overflow-y: scroll;
				padding: 75px 20px 0 20px;
				width: 100%;
			}

			li {
				@extend %list-default;
				border-bottom: 0.75px solid $color-borders-light;
				clear: both;
				flex-direction: column;
				text-align: left;
				position: relative;
				width: 100%;

				&:last-child {
					margin-bottom: 120px;
				}

				&.mod-drop {

					.mod-dropdown-active {
						svg {
							color: $color-primary;
							transform: rotate(-180deg);
						}
					}

					& > a {
						align-items: center;
						color: $color-primary;
						display: flex;
						font-family: $font-secondary;
						font-size: 18px;
						justify-content: space-between;
						letter-spacing: 0.6px;
						padding: 16px 0;
						position: relative;
						text-transform: capitalize;

						@include mq(tablet-down) {
							font-size: 20px;
						}

						& > svg {
							display: block;
						}

						&:hover {
							color: $color-link-hover;
						}
					}
				}

				& > a {
					align-items: center;
					color: $color-primary;
					display: flex;
					font-family: $font-secondary;
					font-size: 18px;
					justify-content: space-between;
					letter-spacing: 0.6px;
					padding: 16px 20px 16px 0;
					position: relative;
					text-transform: capitalize;

					&:hover {
						color: $color-link-hover;
					}

					 @include mq(tablet-down) {
						 font-size: 20px;
						 padding: 16px 0;
					 }
				}

				a {
					& > svg {
						display: none;
						height: 8px;
						margin-left: 15px;
						stroke: $color-primary;
						transition: all 270ms ease-out;
						width: 15px;
					}

					&:hover {
						color: $color-link-hover;
					}
				}

				& > .sub-dropdown-wrap {
					@extend %list-default;
					display: none;

					& li {
						@extend %list-default;
						border-bottom: 0;
						padding: 0;

						& a {
							font-family: $font-standard;
							font-size: 16px;
							font-weight: $font-weight-light;
							justify-content: space-between;
							line-height: 20px;
							padding-bottom: 0;
							padding-top: 25px;
						}

						&:last-child {
							padding: 0 0 45px 0;
						}
					}

					&.mod-active {
						display: block;

						& > .list-sub-nav {
							margin: 0;

							& > .item-nav {

								& a {
									font-family: $font-standard;
									font-size: 15px;
									font-weight: $font-weight-light;
									justify-content: space-between;
									line-height: 20px;
									padding-bottom: 0;
									padding-right: 0;
									padding-top: 20px;

									& svg {
										display: block;
										height: 8px;
										margin-left: 15px;
										position: relative;
										right: 0;
										stroke: $color-primary;
										transition: all 270ms ease-out;
										transform: rotate(-90deg);
										width: 18px;
									}
								}
							}
						}
					}
				}
			}

			.active {

				a {
					//text-decoration: underline;
				}
			}
		}
	}
}
