#page {
	.section-content {
		padding: 140px 0;

		@include mq(tablet-down) {
			padding: 100px 0;
		}

		.wrap-content {
			@extend %container;
		}
	}
}