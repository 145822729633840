.footer-main {
	background: $color-primary;
	color: $color-white;
	display: flex;
	padding: 40px 0 36px 0;
	z-index: 1;

	@include mq(tablet-down) {
		padding: 38px 0 60px 0;
		margin-top: -6px;
	}

	.sub-content-wrapper {
		@extend %container;

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include mq(tablet-down) {
			display: block;
		}

		.sub-content-top, .sub-content-bottom {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
				flex: 1;
			}
		}

		.sub-content-top {
			align-items: center;
			border-bottom: 1px solid rgba($color-white, 0.13);
			margin-bottom: 30px;
			padding-bottom: 35px;
			width: 100%;

			@include mq(tablet-down) {
				border-bottom: none;
				margin-bottom: 0;
				order: 1;
				width: 100%;
			}

			& > .btn-primary {
				border: 1.5px solid #76777a;
				border-radius: 5px;
				color: #fff;
				display: flex;
				justify-content: center;
				letter-spacing: 1.2px;
				margin-right: 24px;
				padding: 15px 15px 13px 15px;
				text-align: center;
				text-transform: uppercase;

				@include mq(tablet-down) {
					font-weight: 300;
					font-size: 12.5px;
					height: 49px;
					margin-right: 0;
					width: 181px;
				}

				span {
					display: block;
					font-weight: $font-weight-light;
					margin-right: 9px;
					width: 15px;
				}
			}
		}

		ul.list-social-links {
			@extend %list-default;

			display: flex;
			justify-content: flex-end;
			flex: 1;

			@include mq(tablet-down) {
				display: none;
			}

			&.mod-mobile {
				display: none;

				@include mq(tablet-down) {
					display: flex !important;
					justify-content: center;
					margin: 45px 0 35px;
				}
			}

			& > li {
				margin-right: 20px;

				a {
					color: $color-white;
					display: block;
					height: 100%;

					svg {
						color: $color-white;
						height: 24px;
						width: 25px;

						&.icon-instagram {
							width: 25px;
						}

						&.icon-facebook {
							width: 14px;
						}
					}

					&:hover {

						svg {

							color: $color-link-hover;
						}
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.sub-content-middle {
			align-items: center;
			display: flex;
			width: 100%;

			@include mq(tablet-down) {
				flex-direction: column;
			}

			ul.list-footer-contact {
				@extend %list-default;

				display: flex;
				flex-direction: column;
				list-style-type: none;

				@include mq(tablet-down) {
					flex-direction: column;
					order: 0;
				}

				& > li {
					@extend %list-default;

					position: relative;

					@include mq(tablet-down) {
						justify-content: center;
						display: flex;
					}

					> a {
						color: $color-white;
						font-family: $font-secondary;
						font-size: 20px;

						@include mq(mobile) {
							font-size: 18px;
							font-weight: 100;
							line-height: 31px;
						}

						span {
							font-size: 18px;
						}
					}

				}
			}

			.sub-logos {
				justify-content: flex-end;
				display: flex;
				flex: 1;

				@include mq(tablet-down) {
					justify-content: center;
					margin-bottom: 20px;
					margin-top: 60px;
					order: 0;
				}

				p {
					margin: 0;
				}

				img {
					max-height: 40px;
					margin-right: 30px;

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}

		.sub-content-bottom {
			align-items: center;
			display: flex;
			margin-top: 25px;
			width: 100%;

			span {
				color: rgba($color-white, .5);
				display: flex;
				flex: 1;

				@include mq(tablet-down) {
					font-size: 10px;
				}
			}

			.sub-footer-links {
				display: flex;
				justify-content: space-between;

				@include mq(tablet-down) {
					flex-direction: column;
					order: 2;
					margin-top: 0px;
				}

				.list-footer-links-left {
					@extend %list-default;

					align-items: center;
					display: flex;
					flex: 1;
					list-style-type: none;

					@include mq(tablet-down) {
						align-self: center;
						flex-wrap: wrap;
						justify-content: center;
						margin-top: 14px;
						order: 1;
						text-align: center;
						width: 75%;
					}

					& > li {
						font-size: 12px;
						letter-spacing: 0.4px;
						opacity: 0.4;

						@include mq(tablet-down) {
							display: inline-block;
							font-size: 10px;
						}

						&:after {
							content: '|';
							color: $color-white;
							margin: 0 15px 0 18px;

							@include mq(tablet-down) {
								margin: 0 10px 0 12px;
							}
						}

						&:nth-child(3):after {
							content: '|';
							color: $color-white;
							margin: 0 15px 0 18px;

							@include mq(tablet-down) {
								margin: 0 10px 0 12px;
							}
						}

						a {
							color: $color-white;
						}

						&:last-child:after {
							content: none;
						}
					}
				}
			}
		}
	}
}
