#contact {
	font-family: $font-standard;
	font-weight: $font-weight-light;

	.header-main {
		border: none;
	}

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 408px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 227px;
		}

		.sub-content {
			align-content: center;
			display: flex;
			height: 100%;
			flex-direction: column;
			justify-content: center;

			h1 {
				align-self: center;
				color: $color-white;
				font-family: $font-secondary;
				font-size: 48px;
				font-weight: $font-weight-light;
				letter-spacing: 1.1px;
				text-align: center;
				margin: 0 auto;
				width: 50%;
				z-index: 999;

				@include mq(tablet-down) {
					font-size: 30px;
					font-weight: $font-weight-light;
					line-height: 32px;
				}
			}

			a {
				align-self: center;
				background-color: #eeece6;
				display: flex;
				font-family: $font-standard;
				font-weight: $font-weight-light;
				justify-content: center;
				margin-top: 50px;
				padding: 10px 30px;
				text-transform: uppercase;
				width: 324px;
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-contact {
		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}

		.aside-contact-details {
			background-color: $color-secondary;
			padding: 60px 5%;
			width: calc(100% / 3);

			@include mq(tablet-down) {
				padding: 27px 25px 40px 25px;
				width: 100%;
			}

			& > h2 {
				color: $color-text-title;
				font-family: $font-secondary;
				font-size: 31px;
				letter-spacing: 0.6px;
				line-height: 42px;
				margin-bottom: 20px;
				max-width: 512px;
				width: 100%;

				@include mq(tablet-down) {
					background-color: $color-secondary;
					font-size: 23px;
					margin-bottom: 7px;
					max-width: none;
				}
			}

			.sub-divider {
				background-color: $color-primary;
				height: 3px;
				margin-bottom: 70px;
				width: 30px;

				@include mq(tablet-down) {
					height: 2px;
					margin-bottom: 30px;
				}
			}

			ul.list-contact-details {
				margin: 0 0 65px 0;
				list-style-type: none;

				@include mq(tablet-down) {
					margin-bottom: 25px;
				}

				& > li {
					margin-left: 0;
					word-break: break-all;

					a {
						color: $color-primary;
						display: flex;
						font-family: $font-secondary;
						font-size: 25px;
						margin-bottom: 10px;

						@media (min-width:1283px) and (max-width:1443px) {
							font-size: 22px;
						}

						@media (min-width:1175px) and (max-width:1283px) {
							font-size: 20px;
						}

						@media (min-width:1025px) and (max-width:1175px) {
							font-size: 17px;
						}

						@include mq(tablet-down) {
							font-size: 19px;
							margin-bottom: 3px;
						}

						span {
							display: block;
							font-weight: $font-weight-light;
							margin-right: 9px;
							width: 15px;
						}
					}
				}
			}

			ul.list-contact-address {
				margin: 0 0 65px 0;
				list-style-type: none;

				@include mq(tablet-down) {
					margin-bottom: 35px;
				}

				& > li {
					color: $color-primary;
					font-size: 16px;
					line-height: 28px;
					margin-left: 0;
				}
			}

			ul.list-social-links {
				@extend %list-default;
				display: flex;

				& > li {
					margin-right: 13px;

					@include mq(tablet-down) {
						margin-right: 18px;
					}

					a {
						display: block;
						height: 100%;

						svg {
							color: $color-primary;
							height: 24px;
							width: 34px;

							&.icon-instagram {
								width: 25px;
							}

							&.icon-facebook {
								width: 14px;
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.section-form {
			padding: 60px 0;
			margin-left: 70px;
			max-width: 568px;
			width: 100%;

			@include mq(tablet-down) {
				margin-left: 0;
				max-width: none;
				padding: 25px;
				width: 100%;
			}


			form {
				@extend %container;

				@include mq(tablet-down) {
					max-width: none;
					margin-bottom: 40px;
					width: 100%;
				}


				.sub-form-copy {
					margin-bottom: 60px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				.form-element {

					input {
						color: #76777a;
						font-size: 16px;
					}

					label {

						span {
							color: #76777a;
							font-size: 16px;
						}
					}

					select {
						-moz-appearance: none;
						-webkit-appearance: none;
						background: transparent;
						border: none;
						border-bottom: 1px solid #c8c8c8;
						color: #76777a;
						display: block;
						font-size: 16px;
						font-weight: 300;
						outline: none;
						padding: 27px 0 20px;
						position: relative;
						text-indent: 1px;
						text-overflow: '';
						width: 100%;
						z-index: 2;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
							text-indent: 0;
						}
					}

					label {
						color: $color-primary;
						font-size: 16px;
						font-weight: $font-weight-light;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
						}
					}

					.carat-icon--down {
						bottom: 26px;
						height: 9px;
						right: 0;
						position: absolute;
						width: 15px;

						g {
							stroke: $color-text-title;
						}
					}
				}

				.sub-errors {
					color: $color-error;
					display: none;
					margin-bottom: 20px;

					.sub-error {
						font-size: $font-size-med;
					}

					span {
						display: block;
						margin-bottom: 3px;
					}
				}

				h2 {
					font-size: 19px;
					font-weight: $font-weight-regular;
					line-height: 30px;

					@include mq(tablet-down) {
						font-size: $font-size-larger;
					}
				}

				.btn-primary {
					background: #eeece6;
					color: $color-primary;
					font-weight: 600;
					justify-content: center;
					margin: 73px 0 39px 0;
					padding: 15px 66px;
					text-align: center;
					text-transform: uppercase;
					width: 100%;

					&:hover {
						color: rgba($color-primary, 0.5);
					}

					@include mq(tablet-down) {
						font-size: $font-size-small;
						margin: 25px auto 0 auto;
						width: 100%;
					}
				}
			}

			.wrap-thanks {
				font-size: $font-size-larger;
				display: none;
			}
		}
	}

	.section-map {
		#map {
			height: 500px;

			@include mq(tablet-down) {
				height: 430px;
			}
		}
	}
}
