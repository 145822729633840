#customer-journey {

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 671px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}

		.sub-content {
			align-content: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;

			h1 {
				align-self: center;
				color: $color-white;
				font-family: $font-secondary;
				font-size: 48px;
				font-weight: $font-weight-light;
				text-align: center;
				margin: 0 auto;
				width: 50%;
				z-index: 10;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 37px;
					width: 85%;
				}
			}

			a {
				align-self: center;
				background-color: #eeece6;
				display: flex;
				font-family: $font-standard;
				font-size: 15px;
				font-weight: $font-weight-light;
				justify-content: center;
				letter-spacing: 1px;
				margin-top: 50px;
				padding: 20px 30px;
				text-transform: uppercase;
				z-index: 10;

				@include mq(tablet-down) {
					display: none;
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-customer-steps {
		padding: 50px 0 200px;
		background: $color-white;
		position: relative;

		@include mq(tablet-down) {
			padding: 30px 0;
		}

		.sub-content {
			@extend %container;
			max-width: 1050px !important;

			@include mq(mobile) {
				padding: 0 30px;
			}

			h2 {
				color: $color-primary;
				font-family: $font-secondary;
				font-size: 31px;
				text-align: center;

				@include mq(tablet-down) {
					margin-bottom: 12px;
				}
			}

			.sub-divider {
				display: none;

				@include mq(tablet-down) {
					background-color: $color-primary;
					display: block;
					height: 2px;
					margin: 0 auto 30px auto;
					width: 35px;
				}
			}

			ul.list-customer-steps {
				@extend %list-default;
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				position: relative;

				&:before {
					background: $color-black;
					content: "";
					height: calc(100% - 264px);
					left: 50%;
					position: absolute;
					top: 0;
					width: 1px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				& > .item-marker {
					background: $color-white;
					border-radius: 50%;
					border: 1px solid $color-black;
					content: "";
					height: 30px;
					margin: auto;
					position: relative;
					width: 30px;

					@include mq(tablet-down) {
						display: none;
					}
				}

				li.item-customer-step {
					flex-basis: calc(50% - 75px);
					flex-grow: 0;
					flex-shrink: 0;
					margin-bottom: 33px;
					position: relative;

					@include mq(tablet-down) {
						flex-basis: 100%;
						margin-bottom: 50px;

						&:after {
							content: '';
							background: transparent;
							border: 1px solid $color-primary;
							border-left: none;
							border-top: none;
							bottom: -30px;
							display: block;
							height: 12px;
							width: 12px;
							left: 50%;
							position: absolute;
							transform: rotate(45deg) translateX(-50%);
						}
					}

					&:last-child {
						&:after {
							display: none;
						}
					}

					&:nth-child(odd) {
						margin-right: 0;
						margin-left: calc(50% + 75px);

						@include mq(tablet-down) {
							margin-left: 0;
						}

						.item-marker {
							right: auto;
							left: -91px;
						}

						&.mod-active .item-marker {
							left: -97px;
						}

						.wrap-content {
							align-items: flex-start;

							@include mq(tablet-down) {
								align-items: center;
								padding-bottom: 10px;
							}

							.sub-content {

								p {
									text-align: left;

									@include mq(tablet-down) {
										text-align: center;
									}
								}
							}
						}

						.wrap-content:before {
							left: auto;
							right: 100%;
							border-left-color: rgba(0, 0, 0, 0);
							border-right-color: #ddddd9;

							@include mq(tablet-down) {
								display: none;
							}
						}
					}

					.item-marker {
						content: "";
						background: $color-white;
						border-radius: 50%;
						border: 1px solid $color-primary;
						box-sizing: content-box;
						height: 30px;
						position: absolute;
						right: -91px;
						top: 50%;
						width: 30px;

						&:before {
							background: $color-white;
							border-radius: 50%;
							border: 1px solid $color-primary;
							content: '';
							display: block;
							height: 18px;
							left: 50%;
							position: absolute;
							top: 50%;
							transform: translate(-50%, -50%);
							width: 18px;
						}

						@include mq(tablet-down) {
							display: none;
						}
					}

					&.mod-active .item-marker {
						width: 12px;
						height: 12px;
						border: 16px solid $color-black;
						top: 39px;
						right: -97px;
					}

					.wrap-content {
						align-items: flex-end;
						display: flex;
						flex-direction: column;
						padding: 12px 12px 30px;
						position: relative;

						@include mq(tablet-down) {
							align-items: center;
							padding-bottom: 10px;
						}

						.sub-content {

							p {
								text-align: right;

								@include mq(tablet-down) {
									text-align: center;
								}
							}
						}
					}

					.sub-image {
						background-size: cover;
						background-repeat: no-repeat;
						height: 162px;
						margin-bottom: 20px;
						width: 255px;

						@include mq(tablet-down) {
							width: 255px;
							margin: 0 auto 20px auto;
						}
					}

					.sub-title {

						h4 {
							color: $color-text-title;
							font-family: $font-secondary;
							font-size: 25px;
							line-height: 30px;
							max-width: 220px;
							margin-bottom: 22px;
							width: 100%;

							@include mq(tablet-down) {
								font-size: 20px;
								max-width: none;
								margin-bottom: 15px;
								text-align: center;
							}
						}
					}

					.sub-content {
						margin: 0;

						@include mq(tablet-down) {
							font-size: $font-size-standard;
							text-align: center;
						}

						p {
							color: #424242;
							font-size: 15px;
							line-height: 26px;
							margin: 0;
							padding: 0;
						}

						p:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

}