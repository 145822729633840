#faqs {

	.section-hero-image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 671px;
		position: relative;
		width: 100%;

		@include mq(tablet-down) {
			height: 355px;
		}

		.sub-content {
			align-content: center;
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			padding-left: 10px;

			h1 {
				align-self: center;
				color: $color-white;
				font-family: $font-secondary;
				font-size: 48px;
				font-weight: $font-weight-light;
				text-align: center;
				margin: 0 auto;
				width: 50%;
				z-index: 10;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 37px;
					width: 85%;
				}
			}

			a {
				align-self: center;
				background-color: #eeece6;
				display: flex;
				font-family: $font-standard;
				font-size: 15px;
				font-weight: $font-weight-light;
				justify-content: center;
				letter-spacing: 1px;
				margin-top: 50px;
				padding: 20px 30px;
				text-transform: uppercase;
				z-index: 10;

				@include mq(tablet-down) {
					display: none;
				}
			}
		}

		.gradient-overlay {
			background: linear-gradient(180deg,#000,rgba(10,34,64,0.32));
			display: block;
			height: 100%;
			left: 0;
			opacity: 0.59;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.section-intro-text {
		margin-bottom: 60px;
		margin-top: 100px;

		@include mq(tablet-down) {
			margin-bottom: 30px;
			margin-top: 40px;
		}

		.sub-content {
			@extend %container;

			.sub-copy {
				max-width: 980px;
				width: 100%;
			}

			@include mq(tablet-down) {
				margin: 0 auto;
				width: 100%;
			}

			h2 {
				color: $color-text-title;
				font-family: $font-secondary;
				font-size: 31px;
				font-weight: $font-weight-regular;
				letter-spacing: 0.6px;
				margin-bottom: 10px;

				@include mq(tablet-down) {
					font-size: 25px;
					line-height: 32px;
					max-width: none;
				}
			}

			p {
				font-size: 16px;
				font-weight: $font-weight-regular;
				line-height: 30.5px;
				margin-bottom: 30px;

				@include mq(tablet-down) {
					font-size: 15px;
					line-height: 27px;
					margin-bottom: 31px;
					max-width: none;
				}
			}

			.sub-divider {
				background-color: $color-primary;
				height: 3px;
				margin-bottom: 30px;
				width: 30px;

				@include mq(tablet-down) {
					height: 2px;
				}
			}
		}
	}

	.section-faqs {
		margin-bottom: 120px;

		@include mq(tablet-down) {
			margin: 70px 0;
		}

		.sub-content {
			@extend %container;

			.sub-copy {
				max-width: 980px;
				width: 100%;
			}


			@include mq(tablet-down) {
				margin: 0 auto;
				width: 100%;
			}

			.list-frequent-questions {
				@extend %list-default;

				.item-frequent-question {

					.sub-faq-question-block {
						align-items: center;
						background-color: $color-secondary;
						cursor: pointer;
						display: flex;
						justify-content: space-between;
						margin-bottom: 5px;
						padding: 30px 25px;

						@include mq(tablet-down) {
							height: unset;
							padding: 20px 15px;
						}

						h2 {
							color: $color-primary;
							font-family: $font-secondary;
							font-size: 20px;
							letter-spacing: 0.4px;
							margin-bottom: 3px;

							@include mq(tablet-down) {
								font-size: 17.5px;
								line-height: 22px;
								width: 80%;
							}
						}

						svg {
							color: #76777A;
							height: 10px;
							transition: all 270ms ease-out;
							width: 14px;
						}

						&.active {
							svg {
								transition: all 270ms ease-out;
								transform: rotate(-180deg);
							}
						}
					}

					.sub-faq-answer-block {

						p {
							font-size: 14px;
							line-height: 29px;
							padding: 15px 0 1px 0;
						}
					}
				}
			}
		}
	}
}